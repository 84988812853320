@import url('https://fonts.googleapis.com/css2?family=Agdasima:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Agdasima:wght@400;700&family=Nanum+Gothic+Coding&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.running-desc {
  font-size: 1.3em;
}

.event-description {
  margin-left: 2%;
}

.text-desc {
  font-family: "Nanum Gothic Coding", monospace;
  font-weight: 900;
  font-style: normal;
}



:root {
  --background: #1a1a1a;
  --color: #fffd82;
  --primary: #00ffff;
  --secondary: #e5e5db;
  --accent: #ff1493;
}

html,
body {
  height: 100%;
}

body {
  display: flex;
  justify-content: center;
  background-color: #242424;
}

.container {
  color: var(--color);
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow-y: auto;
  padding: auto;
  align-items: center;
}

.right {
  text-align: center;
}

.stack {
  display: grid;
  grid-template-columns: 1fr;
  z-index: -1;
}

@import url('https://fonts.googleapis.com/css2?family=Londrina+Sketch&display=swap');

.stack span {
  font-weight: bold;
  grid-row-start: 1;
  grid-column-start: 1;
  font-size: 22rem;
  font-family: "Londrina Sketch", sans-serif;
  font-weight: 400;
  font-style: normal;
  --stack-height: calc(100% / var(--stacks) - 1px);
  --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
  --clip-top: calc(var(--stack-height) * var(--index));
  --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
  clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
  animation: stack 540ms cubic-bezier(.46, .29, 0, 1.24) 1 backwards calc(var(--index) * 120ms), glitch 2s ease infinite 2s alternate-reverse;
}

.stack span:nth-child(odd) {
  --glitch-translate: 8px;
}

.stack span:nth-child(even) {
  --glitch-translate: -8px;
}

@keyframes stack {
  0% {
    opacity: 0;
    transform: translateX(-50%);
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
  }

  60% {
    opacity: 0.5;
    transform: translateX(50%);
  }

  80% {
    transform: none;
    opacity: 1;
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }

  100% {
    text-shadow: none;
  }
}

@keyframes glitch {
  0% {
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
    transform: translate(var(--glitch-translate));
  }

  2% {
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }

  4%,
  100% {
    text-shadow: none;
    transform: none;
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  align-items: center;
  font-family: "Agdasima", sans-serif;
  color: white;

  .navbar-logo {
    font-size: 2rem;
    font-weight: bold;
  }

  .hamburger {
    display: none;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;

    .bar {
      width: 25px;
      height: 3px;
      background: white;
      transition: 0.3s;
    }
  }

  .navbar-links {
    list-style: none;
    display: flex;
    gap: 2rem;

    li a {
      text-decoration: none;
      color: white;
      font-size: 1.07rem;
      font-weight: bold;
      position: relative;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: var(--primary);
      }

      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 2px;
        background-color: var(--primary);
        bottom: -5px;
        left: 0;
        transition: width 0.3s ease-in-out;
      }

      &:hover::after {
        width: 100%;
      }
    }

    .dropdown {
      position: relative;

      .dropbtn {
        cursor: pointer;
      }

      .dropdown-menu {
        position: absolute;
        top: 110%;
        left: 50%;
        transform: translateX(-50%);
        background: rgba(0, 0, 0, 1);
        z-index: 10;
        list-style: none;
        padding: 0.5rem 0;
        margin: 0;
        display: none;
        flex-direction: column;
        min-width: 250px;
        border-radius: 20px;

        li {
          padding: 0.5rem 1rem;

          a {
            color: white;
            text-decoration: none;
            display: block;
            transition: background 0.3s;
            font-size: 0.9rem;
          }
        }
      }

      .dropdown-menu.open {
        display: flex;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .hamburger {
      display: flex;
    }

    .navbar-links {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.9);
      padding: 1rem;
      text-align: center;

      &.open {
        display: flex;
      }
    }

    .dropdown {
      position: relative;

      .dropdown-menu {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
      }
    }
  }
}

.title-head {
  text-align: center;
  font-size: 2.5rem;
  margin: 2rem 0;
  color: var(--secondary);
  font-family: "Agdasima", sans-serif;
}

.magazine-box {
  text-align: center;
  background-color: var(--secondary);
  padding: 2rem;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%;

  h2 {
    margin-bottom: 1rem;
    font-size: 4rem;
    color: var(--accent);
    font-family: "Londrina Sketch", sans-serif;
  }

  p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }

  .magazine-images {
    display: flex;
    justify-content: center;
    gap: 1.5rem;

    img {
      max-width: 300px;
      height: auto;
      border-radius: 8px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }
  }
}

.events-box, .infos-box {
  width: 80%;
}

#events .text-desc {
  text-align: left;
}
/* Media Queries for Responsive Design */
@media (max-width: 668px) {

  .event-description {
    margin-left: 0%;
  }

  .events-box, .infos-box,
  .magazine-box {
    width: 95%;
  }

  .stack span {
    font-size: 12rem;
  }

  .title-head {
    font-size: 2rem;
  }

  .magazine-box {
    padding: 1rem;
  }

  .magazine-box h2 {
    font-size: 3rem;
  }

  .magazine-box p {
    font-size: 1rem;
  }

  .magazine-images {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  .magazine-images img {
    max-width: 100%;
  }

  .running-desc {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .stack span {
    font-size: 9rem;
  }

  .title-head {
    font-size: 1.75rem;
  }

  .navbar-links li a {
    font-size: 0.7rem;
  }

  .navbar {
    padding: 1rem;
  }

  .magazine-box {
    padding: 0.5rem;
  }

  .magazine-box h2 {
    font-size: 2.5rem;
  }

  .magazine-box p {
    font-size: 0.9rem;
  }

  .magazine-images {
    gap: 0.5rem;
  }

  .magazine-images img {
    max-width: 100%;
  }
}

.events-box, .infos-box {
  text-align: center;
  background-color: var(--secondary);
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 2rem auto;
  // max-width: 800px;

  h2 {
    margin-bottom: 1rem;
    font-size: 4rem;
    color: var(--accent);
    font-family: "Londrina Sketch", sans-serif;
  }

  .event-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    img {
      max-width: 200px;
      height: auto;
      border-radius: 8px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }

    .event-description {
      flex: 1;
      text-align: left;

      h3 {
        font-size: 1.5rem;
        color: var(--accent);
        margin-bottom: 0.5rem;
        text-align: left;
      }

      p {
        font-size: 1rem;
        color: #666;
        line-height: 1.6;
      }
    }
  }
}

.footer {
  margin-top: 1cm;
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 40px 0;
  font-family: 'Arial', sans-serif;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* max-width: 1200px;  Uncomment if you want to limit the width */
  margin: 0 auto;
  max-width: 1200px; /* Add max-width for layout control */
}

.footer-section {
  flex: 1;
  padding: 20px;
  min-width: 250px; /* Ensure sections don’t get too small */
}

.footer-section h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #e74c3c;
}

.footer-section p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 10px;
}

.footer-section.about {
  max-width: 400px;
}

.footer-section.links ul {
  list-style-type: none;
  padding: 0;
}

.footer-section.links ul li {
  margin-bottom: 10px;
}

.footer-section.links ul li a {
  color: #ecf0f1;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.footer-section.links ul li a:hover {
  color: #e74c3c;
}

.footer-section.contact p {
  margin-bottom: 5px;
}

.footer-bottom {
  text-align: center;
  padding-top: 20px;
  border-top: 1px solid #34495e;
  margin-top: 40px;
  font-size: 0.9rem;
}

.footer-bottom p {
  margin: 0;
}

.footer-bottom p a {
  color: #e74c3c;
  text-decoration: none;
}

.footer-bottom p a:hover {
  text-decoration: underline;
}

@media (max-width: 668px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .event-item {
    flex-direction: column;
    gap: 1rem;
  }

  .event-description h3,
  p {
    text-align: center;
  }

  .footer-section {
    text-align: center;
  }

  #resource-box {
    display: flex;
    width: 99%;
    flex-direction: column;
  }

}


/* General styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: white;
}

.navbar-logo {
  font-size: 1.5em;
  font-weight: bold;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: 0.4s;
}

.navbar-links {
  display: flex;
  list-style: none;
}

.navbar-links li {
  margin: 0 10px;
}

.navbar-links a {
  color: white;
  text-decoration: none;
  font-size: 1em;
}

/* Mobile styles */
@media (max-width: 668px) {
  .hamburger {
    display: flex;
  }

  .navbar-links {
    position: absolute;
    top: 60px;
    right: 0;
    display: none;
    height: calc(100vh - 60px);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }

  .navbar-links.open {
    width: 100%;
    transform: translateX(0);
    background: rgba(29, 29, 29, 0.7);
    backdrop-filter: blur(100px);
    // margin: 2%;
    border-radius: 5px;
    z-index: 1;
  }

  .navbar-links li {
    margin: 20px 0;
  }

  .bar.open:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .bar.open:nth-child(2) {
    opacity: 0;
  }

  .bar.open:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }

  .boxes {
    display: flex;
    flex-direction: column;
  }


  .close-test {
    display: none !important;
  }

  .infos-box {
    width: 95%;
  }

  .infos-box-2 {
    display: flex;
    flex-direction: column;
  }

  #ch-2 {
    display: flex;
    flex-direction: column;
  }

}

.material-section {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  background-color: #282c34;
}

.boxes {
  display: flex;
  flex-direction: row;
  gap: 2%;
  width: 99%;
  justify-content: center;
}

.google-drive-folder {
  background-color: #1f1f1f;
  border-radius: 10px;
  padding: 20px;
  height: 70vh;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.google-drive-folder h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

iframe {
  border: none;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');


.styled-text {
  font-family: 'Roboto', sans-serif;
  font-size: 32px;
  color: #333;
  line-height: 1.5;
  text-align: center;
}

.styled-text strike {
  color: red;
}

.highlight {
  font-weight: bold;
  display: block;
}

.announcement {
  font-family: "Nanum Gothic Coding", monospace;
  font-size: 20px;
  color: #bac1c8;
  /* Elegant dark blue */
  text-align: center;
  // background-color: #f1f8ff; /* Light blue background */
  padding: 15px 20px;
  border-radius: 10px;
  /* Smooth corners */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for depth */
  margin: 40px auto;
  max-width: 600px;
  /* Constrained width */
}

.announcement a, a {
  color: #e74c3c;
  /* Vibrant red link */
  font-family: "Nanum Gothic Coding", monospace;
  text-decoration: none;
  font-weight: bold;
  border-bottom: 2px solid transparent;
  /* Border animation */
  transition: border-bottom 0.3s ease;
}

.announcement a:hover {
  border-bottom: 2px solid #e74c3c;
  /* Underline effect on hover */
}

.announcement::before {
  font-size: 24px;
  margin-right: 8px;
}


.button {
  margin-top: 0.925rem;
  padding: 0.5rem 0.9375rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #602f56;
  color: white;
  cursor: pointer;
  letter-spacing: 2px;
  font-size: 1.4rem;
  transition: letter-spacing linear 0.2s;
}

.button:hover {
  background-color: #810a6a;
  letter-spacing: 4px;
}


.infos-box-2 {
  display: flex;
  flex-direction: row;
  gap: 5%;
  font-size: 1.4rem;
  color: #666;
}


.infos-box-child {
  padding: 5%;
}

.social-links {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.social-links .social-icon {
  width: 30px;
  height: 30px;
}

/* Dropdown Container */
.dropdown-container-1 {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.year-dropdown-1 {
  width: 200px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Charts Row */
.charts-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 20px;
}

/* Chart Container */
.chart-container-1 {
  width: 40%; /* Adjusts the width of each chart */
  min-width: 300px; /* Ensures the charts don't shrink too much */
  text-align: center;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.chart-container-1:hover {
  transform: translateY(-5px); /* Adds a hover effect */
}

.chart-container-1 p {
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

/* Note and Last Updated */
.events-box p {
  margin-top: 20px;
  font-size: 13px;
  color: #555;
  text-align: center;
}

.announcement {
  background: rgba(0, 0, 0, 0.7);
  color: #0ff; /* Neon cyan */
  font-size: clamp(1rem, 2vw, 1.5rem); /* Responsive font size */
  font-weight: bold;
  padding: 20px 40px;
  border-radius: 12px;
  text-align: center;
  position: relative;
  box-shadow: 0 0 15px #0ff, 0 0 30px rgba(0, 255, 255, 0.5);
  animation: flicker 1.5s infinite alternate;
  display: inline-block;
  max-width: 90%; /* Ensures it doesn't overflow on small screens */
  white-space: nowrap; /* Prevents text wrapping */
}

@media (max-width: 600px) {
  .announcement {
      padding: 10px 3vw;
      font-size: 1rem;
      white-space: normal; /* Allows text wrapping on small screens */
  }
}


.announcement strong {
  color: #ff007f; /* Neon pink */
  text-shadow: 0 0 10px #ff007f, 0 0 20px #ff007f;
  animation: glowText 1.5s infinite alternate;
}

.announcement a {
  color: #ffea00; /* Neon yellow */
  text-decoration: none;
  font-weight: bold;
  padding: 5px 12px;
  background: rgba(255, 234, 0, 0.2);
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  text-shadow: 0 0 8px #ffea00;
}

.announcement a:hover {
  background: #ffea00;
  color: black;
  box-shadow: 0 0 12px #ffea00;
}

@keyframes glowText {
  0% { text-shadow: 0 0 5px #ff007f, 0 0 15px #ff007f; }
  100% { text-shadow: 0 0 15px #ff007f, 0 0 25px #ff007f; }
}

@keyframes flicker {
  0% { opacity: 1; }
  50% { opacity: 0.85; }
  100% { opacity: 1; }
}

